import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ConsultForm from 'shared/ConsultForm';
import ym from 'react-yandex-metrika';

const telegram = require("assets/images/telegram.svg").default;
const youtube = require("assets/images/youtube.svg").default;
const whatsapp = require("assets/images/whatsapp.svg").default;
const vkontakte = require("assets/images/vkontakte.svg").default;

const ContactsPage = ({setPageClass}) => {
    const navigate = useNavigate();

    useEffect(() => {
        setPageClass('contacts-page');
        ym('hit', '/kontakty');
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
		<div className="contacts">
			<HeadTags
				title='Контакты психолога - Елена Полянская'
				metaDescription='Контактная информация психолога Елены Полянской. Телефон, E-mail, Telegram, WhatsApp'
			/>
		    <section className="container">
                <ul className="breadcrumbs">
                    <li><Link to="/" itemProp="item">главная</Link></li>
                    <li><span>КОНТАКТЫ</span></li>
                </ul>
	            <h1 className="h2">КОНТАКТЫ</h1>
	            <div className="contacts__block">
	            	<div className="contacts__text">
		            	<a className="tel" href="tel:+79683210182">+7 (968) 321-01-82</a>
                        <a className="mail" href="mailto:info@psyterapevt.ru">info@psyterapevt.ru</a>
			        </div>
                    <div className="contacts__links">
                        <a href="https://vk.com/polyanskaya_en" target="_blank" rel="nofollow noopener noreferrer" className="contacts__link">
                            <img src={vkontakte} alt="vkontakte" width="53" />
                            <span>Вконтакте</span>
                        </a>
                        <a href="https://telegram.me/psyterapevtEP" target="_blank" rel="nofollow noopener noreferrer" className="contacts__link">
                            <img src={telegram} alt="telegram" width="53" />
                            <span>Telegram</span>
                        </a>
                        <a href="https://www.youtube.com/channel/UCpC26R1Q29O0Copkf2usuRA" target="_blank" rel="nofollow noopener noreferrer" className="contacts__link">
                            <img src={youtube} alt="youtube" width="50" />
                            <span>YouTube</span>
                        </a>
                        <a href="https://wa.me/79683210182" target="_blank" rel="nofollow noopener noreferrer" className="contacts__link">
                            <img src={whatsapp} alt="whatsapp" width="53" />
                            <span>WhatsApp</span>
                        </a>
                    </div>
		        </div>
                <ConsultForm/>
	        </section>
	    </div>
	)
}
export default ContactsPage;