import React, { useEffect, useState, useRef } from 'react';
import LoadingBlock from 'shared/LoadingBlock';
import { Link, useNavigate } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useGetTeamListQuery, useGetVideosListQuery } from 'services/api/api';
import ym from 'react-yandex-metrika';

const newYearImg = require("assets/images/ny_3.1.jpg");
const newYearImgSm = require("assets/images/ny_3.2.jpg");

// const videos = [
// 	{id: '7395d528ab2df8f4a1e94bc985a6a9f8'},
// 	{id: '1c96063f303640ae1b960343d8b76fc1'},
// 	{id: '43eee81b0f58725fee40b039fcadfb75'},
// 	{id: 'b43c6582170e38676520c6dc9aa3cc2e'},
// 	{id: '2dee23f812f44555dd0c05dfbb548e7d'},
// 	{id: 'd1ed8b8d80225245c361178a301f5b89'},
// 	{id: 'ff9d6908d4ee97eb0d218985f28bbeea'},
// 	{id: 'd8fc93805a0c6003b9a4cb5915b9cd1f'}
// ];

const VideoItem = ({video}) => {
  return (
    <div className="slick-slide">
      <div className="main-video-item">
        <iframe
          src={`${video.link}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
};
const HomePage = ({ setPageClass }) => {
  const { data, isLoading } = useGetTeamListQuery();
  const { data: videos, isLoading: isVideosLoading } = useGetVideosListQuery();
  const navigate = useNavigate();

  useEffect(() => {
    setPageClass('main-page');
    ym('hit', '/');
    const _tmr = window['_tmr'] || (window['_tmr'] = []);
    _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
  }, []);
  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}, [navigate]);

  return (
    <>
      <HeadTags
        title='Центр современной психологии Елены Полянской - официальный сайт'
        metaDescription='Официальный сайт Елены Полянской. На сайте предлагаются услуги онлайн-образования и индивидуального консультирования. Вы можете найти информацию о курсах, тренингах и консультациях.'
      />
      <div className="container">
        {/* <div style={{paddingTop : '40px'}}>   
          <picture>
            <source media="(max-width: 480px)" srcSet={newYearImgSm} /> 
            <img alt="С Новым Годом!" src={newYearImg} title="С Новым Годом!" width="100%" />
          </picture>            
        </div> */}
        <div className="main-text">
          <p>Центр современной психологии Елены Полянской рад приветствовать вас на своем сайте. Мы занимаемся онлайн-образованием и индивидуальным консультированием. У нас вы можете найти помощь в решении жизненных задач, связанных с отношениями в семье, на работе, вашим личным состоянием.</p>
          <p>Здесь Вы можете найти информацию, с помощью которой станет понятно, как устроены разного рода проблемы и какие способы их решения существуют в психологии и духовных практиках:</p>
          <ul className="check-list">
            <li>Статьи;</li>
            <li>Техники и практики;</li>
            <li>Вебинары и курсы;</li>
            <li>Личные консультации.</li>
          </ul>
          <p>Если вам нужен психолог — зайдите в раздел «Команда». Посмотрите представленные анкеты и фотографии наших мастеров. Каждый специалист презентует свои возможности, навыки и умения. Ваша задача — прислушаться к себе и выбрать того, кто по вашим ощущениям вам больше подходит.</p>
        </div>
        <section className="main-shedule">
          <h2>Расписание курсов</h2>
          <div className="main-shedule-list">
            <Link to="" className="main-shedule-list__item main-shedule-list__item--orange-img">
              <span className="main-shedule-list__date">10-15 августа</span>
              <span className="main-shedule-list__name">Вся семейная психотерапия в формулах</span>
              <span className="main-shedule-list__type">психологические курсы по саморазвитию</span>
            </Link>
            <Link to="" className="main-shedule-list__item main-shedule-list__item--decor">
              <span className="main-shedule-list__date">10-15 августа</span>
              <span className="main-shedule-list__name"><strong>Содержание отношений:</strong> коммуникации, иерархия, семейные мифы и темы, темы   поколений или трансгенерационная травма.</span>
            </Link>
            <Link to="" className="main-shedule-list__item main-shedule-list__item--gray-img">
              <span className="main-shedule-list__date">10-15 августа</span>
              <span className="main-shedule-list__name">Выборы и вызовы, чем предопределены и в чем свобода.</span>
              <span className="main-shedule-list__type">психологические курсы по саморазвитию</span>
            </Link>
            <Link to="" className="main-shedule-list__item main-shedule-list__item--photo">
              <span className="main-shedule-list__date">10-15 августа</span>
              <span className="main-shedule-list__name"><strong>Азы и космос</strong> расстановок</span>
              <span className="main-shedule-list__type">психологические курсы по саморазвитию</span>
            </Link>
            <Link to="" className="main-shedule-list__item main-shedule-list__item--orange">
              <span className="main-shedule-list__date">10-15 августа</span>
              <span className="main-shedule-list__name">Генограмма, или как мы выбираем партнеров и профессию</span>
              <span className="main-shedule-list__type">авторский 4-х часовой курс</span>
            </Link>
          </div>
        </section>
      </div>
      <div className="main-decor">
        <section className="main-about">
          <div className="container">
            <h2>Наша команда</h2>
            <div className="main-about__text">
              <div className="main-about__text-top">
                <p className="main-about__subtitle">Елена <span className="main-about__subtitle-left">Полянская</span></p>
                <p className="main-about__title">Кто я?</p>
                <p>Я, Елена Полянская, психолог, работающий с человеческими чувствами, проблемами, болезнями.</p>
                <p>Работаю через контакт с чувствами, телом, семьей, родом и большими процессами, затрагивающими человека. Работаю на исцеление, которое гармонизирует и тело, и душу.</p>
              </div>
              <p className="main-about__title">Мой рабочий инструмент:</p>
              <ul className="check-list">
                <li>знания (ВУЗ, много дополнительного образования),</li>
                <li>хороший контакт с собой (много лет работы со своими психологами и мои родители, которые этому не мешали),</li>
                <li>опыт (работа в соматических и психиатрическом стационарах, работа с детьми с расстройствами психического развития и их семьями, более 13 лет частной психологической практики),</li>
                <li>сверхчувствительность, от которой я раньше мучилась, а теперь наслаждаюсь.</li>
              </ul>
              <p className="main-about__title">С чем ко мне можно обращаться?</p>
              <p>- Если хочется понять, по каким законам устроены отношения в семье, роду, в паре.</p>
              <p>- Если хочется не просто понять общее устройство человеческих взаимоотношений, но увидеть, что такое происходит с вами и именно в вашей семье, роду, с вашими отношениями – семейные сценарии, родовые проблемы, переходящие из поколения в поколение.</p>
              <p className="main-about__title">Увидеть и поправить.</p>
              <p>- Эмоциональные переживания и чувства.</p>
              <p>- Экзистенциальные вызовы – темы Смерти, Одиночества, Свободы.</p>
              <p>- Со всем, если вам надо решить глубоко и по-настоящему. Результат будет зависеть от того, насколько есть ресурс и готовность к изменениям.</p>
              <Link to="/elena-polyanskaya" className="main-about__more gradient btn">ПОДРОБНЕЕ</Link>
            </div>
          </div>
        </section>
        <section className="main-team container">
          <h2>Ведущие психологи</h2>
          <div className="main-team__text">
            <p className="main-team__title">Кто мы?</p>
            <p>Мы – команда психологов, готовых работать и помогать с разным калибром и спектром человеческих переживаний и чувств.</p>
          </div>
          <LoadingBlock isLoading={isLoading}>
            <ul className="main-team-list">
              {data
                ? data.length
                  ? <>{data.filter(person => person.surname !== "Полянская").map(person => {
                      return (
                        <li className="main-team-item" key={person.id}>
                          <Link to={`/komanda/${person.slug}`} className="main-team-item__photo">
                            <img src={person.thumbnail} alt={`${person.name} ${person.surname}`} />
                            <span className="main-team-item__name">{person.name} <span className="main-team-item__name-right">{person.surname}</span></span>
                          </Link>
                          <Link to={`/komanda/${person.slug}`} className="main-team-item__more gradient btn">ПОДРОБНЕЕ</Link>
                        </li>
                      )
                    })}</>
                  : null
                : <p>Загрузка ...</p>}
            </ul>
          </LoadingBlock>
        </section>
      </div>
      <section className="main-services">
        <div className="container">
          <h3>Наши услуги</h3>
          <ul className="main-services-list">
            <li>
              <Link to="/uslugi/lichnoe-konsultirovanie" className="main-services-list__item">
                <span className="main-services-list__item-name">Личное консультирование </span>
              </Link>
            </li>
            <li>
              <Link to="/uslugi/gruppovaya-terapiya" className="main-services-list__item">
                <span className="main-services-list__item-name">Групповая терапия</span>
              </Link>
            </li>
            <li>
              <Link to="" className="main-services-list__item">
                <span className="main-services-list__item-name">Обучающие группы</span>
              </Link>
            </li>
            <li>
              <Link to="/uslugi/retriti" className="main-services-list__item">
                <span className="main-services-list__item-name">Ретриты</span>
              </Link>
            </li>
            <li>
              <Link to="/vebinari" className="main-services-list__item">
                <span className="main-services-list__item-name">Вебинары</span>
              </Link>
            </li>
            <li>
              <Link to="/kursi" className="main-services-list__item">
                <span className="main-services-list__item-name">Курсы</span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="main-video container">
        <LoadingBlock isLoading={isVideosLoading}>
          <>
          <h3>Видео </h3>
          <div className="main-video-list">
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={0}
              slidesPerView={1}
              pagination={{
                el: '.swiper-pagination',
              }}
              navigation={{
                nextEl: '#video-next',
                prevEl: '#video-prev',
              }}
              breakpoints={{
                '769': {
                  slidesPerView: 2
                }
              }}
              loop
              lazy>
              {videos
                ? videos.length
                  ? <ul className="video-list">
                  {videos.map((video, i) =>
                          <SwiperSlide key={video.id}>
                            <VideoItem
                              video={video} />
                          </SwiperSlide>
                        )}
                  </ul>
                  : null
                : <p>Загрузка ...</p>}
            </Swiper>
            <div className="swiper-pagination"></div>
            <div className="swiper-button-prev" id="video-prev"></div>
            <div className="swiper-button-next" id="video-next"></div>
          </div>
          </>
        </LoadingBlock>
      </section>
    </>
  )
};
export default HomePage;